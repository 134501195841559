import React, { useEffect, useState } from 'react';
import './css/home.css';
import { Link } from 'react-router-dom';
import { client } from '../types';
import Header from '../components/header';
import { Box, Button, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { intervalTime } from '../utils';
import Title from '../components/Title';

function Client() {

  const [newClient, setNewClient] = useState<{ type: string, number: string, name: string }>({
    type: "PM",
    number: "555-",
    name: ""
  })

  const [clientList, setClientList] = useState<client[]>()

  const [recherche, setRecherche] = useState<{
    name: string,
    number: string
  }>({
    name: "",
    number: "555-"
  })

  useEffect(() => {
    getClientList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])

  const addClient = async () => {
    if (newClient.name === "") return
    await fetch(process.env.REACT_APP_BACKEND_URL + '/client/add', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(newClient)
    })

    setNewClient(prev => ({
      ...prev,
      name: "",
      number: "555-"
    }))
    getClientList()

  }

  const getClientList = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/client/list', {
      mode: 'cors',
      method: "GET",
      credentials: "include"
    })

    const data: client[] = await response.json()

    setClientList(data)

  }

  return (
    <>
      <Title variant="h4"  text='Ajouter un client'/>

      <Paper elevation={16} sx={{ p: 2, display: 'flex', flexDirection: "row", margin: '1rem' }}>
        <TextField sx={{ margin: "1rem" }} id='stockage' select label="Type" variant='outlined' value={newClient.type} onChange={(e) => { setNewClient(prev => ({ ...prev, type: e.target.value })) }}>

          <MenuItem key={"Petite Main"} value={"PM"}>
            PM
          </MenuItem>
          <MenuItem key={"Groupe"} value={"Groupe"}>
            Groupe
          </MenuItem>
        </TextField>
        <TextField sx={{ margin: "1rem" }} id='item' label="Numero" variant='outlined' error={!newClient.number.startsWith("555-") || newClient.number.length > 8} value={newClient.number} onChange={(e) => { setNewClient(prev => ({ ...prev, number: e.target.value })) }}>

        </TextField>
        <TextField sx={{ margin: "1rem" }} id='mouvement' label="Nom Prenom" variant='outlined' error={newClient.name === ""} value={newClient.name} onChange={(e) => { setNewClient(prev => ({ ...prev, name: e.target.value })) }} />

        <Button onClick={addClient}>Ajouter</Button>
      </Paper>

      <Title variant="h4"  text='Rechercher un client'/>

      <Paper elevation={16} sx={{ p: 2, display: 'flex', flexDirection: "row", margin: '1rem' }}>
        <TextField sx={{ margin: "1rem" }} id='numero' label="Numero" variant='outlined' error={!recherche.number.startsWith("555-") || recherche.number.length > 8} value={recherche.number} onChange={(e) => { setRecherche(prev => ({ ...prev, number: e.target.value })) }} />
        <TextField sx={{ margin: "1rem" }} id="nom" variant='outlined' label="Prenom" value={recherche.name} onChange={(e) => setRecherche(prev => ({ ...prev, name: e.target.value }))} />

      </Paper>
      
      <Title variant="h4"  text='Ajouter un client'/>

      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>Numero</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Nom</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Type</TableCell>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientList?.filter(client => {
              if (!client.name.toLowerCase().includes(recherche.name.toLowerCase())) return
              if (!client.number.includes(recherche.number)) return
              return client
            })?.map(client => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell sx={{ textAlign: "center" }}>{client.number}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{client.name}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{client.type}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Button component={Link} to={`/transaction?id=${client.id}`}>Gestion Client</Button>
                  </TableCell>

                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}

export default Client;
