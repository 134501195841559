import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useNavigate } from 'react-router-dom';
import { item, User } from '../types';
import { intervalTime, role } from '../utils';
import { Box, Table, TableRow, TableCell, TableBody, Button, TextField, Checkbox, FormControlLabel, Paper, Typography, Snackbar, Alert, Popover } from '@mui/material';
import { MuiColorInput, MuiColorInputColors, MuiColorInputValue } from 'mui-color-input'

function GestionItem({ user }: { user: User | undefined }) {
  let navigate = useNavigate()

  if (!user || user.role !== role.admin) navigate('/')


  const [open, setOpen] = useState(false)
  const [notifText, setNotifText] = useState("")
  const handleClose = () => setOpen(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    , item: item) => {
    setEditedItem(item)
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => setAnchorEl(null);

  const isPopoverOpen = Boolean(anchorEl)

  const [itemList, setItemList] = useState<item[]>()

  const [value, setValue] = React.useState<MuiColorInputValue>('#ffffff')

  const handleChange = (newValue: string, colors: MuiColorInputColors) => {
    setValue(newValue)
  }

  const [newItem, setNewItem] = useState<item>({
    alwaysShow: false,
    color: "",
    name: "",
    poids: 0,
    id: 0,
    isWeapon: false,
  })

  const [editedItem, setEditedItem] = useState<item>({
    alwaysShow: false,
    color: "",
    name: "",
    poids: 0,
    id: 0,
    isWeapon: false,
  })

  useEffect(() => {
    getItemList()
    const interval = setInterval(() => {
      getItemList()
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getItemList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/itemList', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: item[] = await response.json();
      setItemList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }


  const editItem = async () => {
    // let item

    // if (!itemList) return
    // for (let i = 0; i < itemList.length; i++) {
    //   if (itemList[i].id === id) {
    //     item = itemList[i]
    //     break
    //   }
    // }
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/item/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ editedItem })
    })


    setNotifText("L'item a bien été modifié")
    getItemList()
    setOpen(true)
    handleClosePopover()
  }

  const addItem = async () => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/item/create', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ newItem })
    })

    getItemList()
    setNewItem({
      alwaysShow: false,
      color: "",
      name: "",
      poids: 0,
      id: 0,
      isWeapon: false
    })

    setNotifText("L'item a bien été ajouté")
    setOpen(true)
  }

  return (
    <>

      <Box sx={{ p: 2 }}>
        <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          Liste Item
        </Typography>
      </Box>
      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableBody>
            {itemList?.map(item => {
              return (
                <TableRow key={Math.random()} >
                  <TableCell sx={{textAlign: "center", color: item.color}}>
                   {item.name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }} >
                   {item.poids}g
                  </TableCell>
                  <TableCell sx={{textAlign: "center", color: item.color}}>
                    {item.color}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={item.alwaysShow}
                        />
                      }
                      label="Toujours Montrer"
                      labelPlacement='end'
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={item.isWeapon}
                        />
                      }
                      label="Arme ?"
                      labelPlacement='end'
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }} >

                    <Button color='warning' variant='contained' sx={{ textAlign: "center" }} onClick={(e) => handleOpen(e, item)}>Modifier</Button>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow key={Math.random()} >
              <TableCell >
                <TextField size='small' id='mouvement' label="Nom" defaultValue={newItem.name} variant='outlined' onBlur={(e) => setNewItem(prev => ({ ...prev, name: e.target.value }))} />
              </TableCell>
              <TableCell sx={{ textAlign: "center" }} >
                <TextField size='small' id='mouvement' label="Poids" defaultValue={newItem.poids} variant='outlined' onBlur={(e) => setNewItem(prev => ({ ...prev, poids: parseInt(e.target.value) }))} />
              </TableCell>
              <TableCell sx={{ textAlign: "center" }} >
                <TextField size='small' id='mouvement' label="Couleur" defaultValue={newItem.color} variant='outlined' onBlur={(e) => setNewItem(prev => ({ ...prev, color: e.target.value }))} />
              </TableCell>
              <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={newItem.alwaysShow} onChange={(e) => setNewItem(prev => ({ ...prev, alwaysShow: e.target.checked }))}
                    />
                  }
                  label="Toujours Montrer"
                  labelPlacement='end'
                />
              </TableCell>
              <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={newItem.isWeapon} onChange={(e) => setNewItem(prev => ({ ...prev, isWeapon: e.target.checked }))}
                    />
                  }
                  label="Arme ?"
                  labelPlacement='end'
                />
              </TableCell>
              <TableCell sx={{ textAlign: "center" }} >

                <Button color='success' variant='contained' sx={{ textAlign: "center" }} onClick={() => addItem()}>Ajouter</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <br />

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="success" variant='filled'>{notifText}</Alert>
      </Snackbar>

      <MuiColorInput value={value} onChange={handleChange} format={"hex"} isAlphaHidden />



      <Popover
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'color-mix(in oklab, color-mix(in oklab, #282c34, black 50%), transparent 20%)',
          backdropFilter: 'blur(5px)'
        }}
        open={isPopoverOpen}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorReference='none'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper elevation={16} sx={{ display: 'flex', flexDirection: "column", p: 2 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
            Modifier un item
            {/* {mouvementStock.type === "fix" ? "Modifier le stock" : "Ajouter/Retirer au stock"} */}
          </Typography>

          
          <TextField sx={{ margin: "1rem" }} size='small' id='mouvement' label="Nom" value={editedItem.name} variant='outlined' onChange={(e) => setEditedItem(prev => ({ ...prev, name: e.target.value }))} />

          <TextField  sx={{ margin: "1rem" }}size='small' id='mouvement' label="Poids" value={editedItem.poids} variant='outlined' onChange={(e) => setEditedItem(prev => ({ ...prev, poids: parseInt(e.target.value) }))} />

          <TextField sx={{ margin: "1rem" }} size='small' id='mouvement' label="Couleur" value={editedItem.color} variant='outlined' onChange={(e) => setEditedItem(prev => ({ ...prev, color: e.target.value }))} />

          <FormControlLabel 
            control={
              <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={editedItem.alwaysShow} onChange={(e) => setEditedItem(prev => ({ ...prev, alwaysShow: e.target.checked }))}
              />
            }
            label="Toujours Montrer"
            labelPlacement='bottom'
          />

          <FormControlLabel
            control={
              <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={editedItem.isWeapon} onChange={(e) => setEditedItem(prev => ({ ...prev, isWeapon: e.target.checked }))}
              />
            }
            label="Arme ?"
            labelPlacement='bottom'
          />

          <Button onClick={editItem}>Valider</Button>
        </Paper>
      </Popover>

    </>
  );
}

export default GestionItem;
