import { useEffect, useState } from "react";
import { User } from "../types";
import { Link, useNavigate } from "react-router-dom";
import logo from "../pages/asset/Mano.png";

import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import HistoryIcon from '@mui/icons-material/History';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import LocalLibraryOutlined from '@mui/icons-material/LocalLibraryOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { AppBar, Box, Button, Divider, Menu, MenuItem, Toolbar } from "@mui/material";
import React from "react";

export default function Header({ user }: { user: User | undefined }) {

    let navigate = useNavigate()

    const [annuaireList, setAnnuaireList] = useState<{ id: number; name: string; }[]>()

    const [anchorElAnnuaire, setAnchorElAnnuaire] = React.useState<null | HTMLElement>(null);
    const openAnnuaire = Boolean(anchorElAnnuaire);
    const handleClickAnnuaire = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElAnnuaire(event.currentTarget);
    };
    const handleCloseAnnuaire = () => {
        setAnchorElAnnuaire(null);
    };

    const [anchorElAdmin, setAnchorElAdmin] = React.useState<null | HTMLElement>(null);
    const openAdmin = Boolean(anchorElAdmin);
    const handleClickAdmin = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElAdmin(event.currentTarget);
    };
    const handleCloseAdmin = () => {
        setAnchorElAdmin(null);
    };

    useEffect(() => {
        getAnnuaireList()
    }, [])

    const getAnnuaireList = async () => {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/annuaire/list', {
            mode: 'cors',
            method: "GET",
            credentials: "include"
        })

        const data: {
            id: number;
            name: string;
        }[] = await response.json();

        setAnnuaireList(data)

    }


    const goToHome = () => {
        navigate('/')
    }

    return (
        <Box>
            <AppBar position='fixed'>
                <Toolbar>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ flexGrow: 1, alignContent: 'center' }}>
                            <Box onClick={goToHome} sx={{ cursor: 'pointer' }} >
                                <img src={logo} alt="logo mano" />
                            </Box>
                        </Box>
                        <Button component={Link} to='/outils' variant='text' startIcon={<BuildCircleOutlinedIcon />} sx={{ color: 'text.primary' }}>
                            Outils
                        </Button>
                        <Button component={Link} to='/prix_groupe' variant='text' startIcon={<LocalOfferOutlinedIcon />} sx={{ color: 'text.primary' }}>
                            Prix Groupe
                        </Button>
                        <Box>
                            <div className="header-container">
                                <Button
                                    id="basic-button"
                                    aria-controls={openAnnuaire ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openAnnuaire ? 'true' : undefined}
                                    onClick={handleClickAnnuaire}
                                    startIcon={<LocalLibraryOutlined />}
                                    endIcon={<KeyboardArrowDownIcon />}
                                    sx={{ color: 'text.primary' }}
                                >
                                    Annuaire
                                </Button>
                                <Button component={Link} to='/client' variant='text' startIcon={<Person2OutlinedIcon />} sx={{ color: 'text.primary' }}>
                                    Client
                                </Button>
                                <Button component={Link} to='/stockage' variant='text' startIcon={<FolderOpenOutlinedIcon />} sx={{ color: 'text.primary' }}>
                                    Stockage
                                </Button>
                                <Button component={Link} to='/historique' variant='text' startIcon={<HistoryIcon />} sx={{ color: 'text.primary' }}>
                                    Historique
                                </Button>

                                <Button component={Link} to='/caisse_noire' variant='text' startIcon={<AccountBalanceOutlinedIcon />} sx={{ color: 'text.primary' }}>
                                    Caisse Noire
                                </Button>
                                <Button component={Link} to='/remboursement' variant='text' startIcon={<MonetizationOnOutlinedIcon />} sx={{ color: 'text.primary' }}>
                                    Remboursement
                                </Button>
                                <Button component={Link} to='/statistique' variant='text' startIcon={<AnalyticsOutlinedIcon />} sx={{ color: 'text.primary' }}>
                                    Statistique
                                </Button>


                                {
                                    user?.role === "Admin" &&
                                    (
                                        <Button
                                            id="basic-button"
                                            aria-controls={openAdmin ? 'admin-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openAdmin ? 'true' : undefined}
                                            onClick={handleClickAdmin}
                                            startIcon={<SupervisorAccountIcon />}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            sx={{ color: "text.primary", }}
                                        >
                                            Gestion
                                        </Button>
                                    )


                                }

                                <Menu
                                    id="admin-menu"
                                    anchorEl={anchorElAdmin}
                                    open={openAdmin}
                                    onClose={handleCloseAdmin}
                                >

                                    <MenuItem onClick={() => { navigate(`/gestion/item`) }}>Gestion Item</MenuItem>
                                    <MenuItem onClick={() => { navigate(`/gestion/user`) }}>Gestion Utilisateur</MenuItem>
                                    <MenuItem onClick={() => { navigate(`/gestion/prix`) }}>Gestion Prix</MenuItem>
                                    <MenuItem onClick={() => { navigate(`/gestion/caisse_noire`) }}>Gestion CN</MenuItem>
                                    <MenuItem onClick={() => { navigate(`/gestion/mouvement_CN`) }}>Edition Mouvement CN</MenuItem>

                                </Menu>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorElAnnuaire}
                                    open={openAnnuaire}
                                    onClose={handleCloseAnnuaire}
                                >
                                    <MenuItem onClick={() => { navigate(`/annuaire`) }}>Annuaire</MenuItem>
                                    <Divider />
                                    {
                                        annuaireList?.map(annuaire => {
                                            return (
                                                <MenuItem onClick={() => { navigate(`/annuaire?id=${annuaire.id}`) }}>{annuaire.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Menu>
                                <div className="username">{`${user?.name} (${user?.role})`}</div>
                            </div>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Box>
    )
}