import { Route, Routes, useNavigate } from "react-router-dom"
import Layout from "../components/Layout"
import Annuaire from "../pages/Annuaire"
import CaisseNoir from "../pages/caisse_noir"
import GestionCN from "../pages/GestionCN"
import GestionItem from "../pages/GestionItem"
import GestionPrice from "../pages/GestionPrice"
import GestionUser from "../pages/GestionUser"
import Historique from "../pages/historique"
import App from "../pages/home"
import Outils from "../pages/Outils"
import Remboursement from "../pages/Remboursement"
import Statistique from "../pages/statistique"
import Stockage from "../pages/stockage"
import Transaction from "../pages/Transaction"
import Client from "../pages/client"
import Discord from "../pages/discord"
import { useEffect, useState } from "react"
import { User } from "../types"
import EditionMouvementCN from "../pages/EditionMouvementCN"
import GroupePrice from "../pages/GroupePrice"

const AppRoutes = () => {
    const navigate = useNavigate()
    const [user, setUser] = useState<User >();
    const [loading, setLoading] = useState(true);

    const getUser = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user/profile', {
                mode: 'cors',
                method: "GET",
                credentials: "include"
            })
            if (response.status === 400) {
                setLoading(false)
                return false
            }
            const data: User = await response.json();
            setLoading(false)
            setUser(data)
            return true


        } catch (error) {
            console.error('Error get user data', error);
        }
    }

    useEffect(() => {
        (async function() {
            if(loading) {
                const isLogged = await getUser()
                if(!isLogged) navigate('/discord')
            }
    })()
    }, [loading])

    if (loading) return null

    return (
        <Routes>
            <Route element={<Layout user={user} />}>
                <Route path='/' element={<App user={user} />} />
                <Route path='/caisse_noire' element={<CaisseNoir />} />
                <Route path='/stockage' element={<Stockage />} />
                <Route path='/historique' element={<Historique />} />
                <Route path='/client' element={<Client />} />
                <Route path='/remboursement' element={<Remboursement user={user} />} />
                <Route path='/transaction' element={<Transaction />} />
                <Route path='/annuaire' element={<Annuaire />} />
                <Route path='/outils' element={<Outils />} />
                <Route path='/statistique' element={<Statistique />} />
                <Route path='/prix_groupe' element={<GroupePrice />} />


                <Route path='/gestion/item' element={<GestionItem user={user} />} />
                <Route path='/gestion/user' element={<GestionUser user={user} />} />
                <Route path='/gestion/prix' element={<GestionPrice user={user} />} />
                <Route path='/gestion/caisse_noire' element={<GestionCN user={user} />} />
                <Route path='/gestion/mouvement_CN' element={<EditionMouvementCN user={user} />} />
            </Route>
            <Route path='/discord' element={<Discord />} />

        </Routes>
    )
}

export default AppRoutes



// const router = createBrowserRouter([
//   {
//     element: <Layout />,
//     children: [
//       {
//         path: "/",
//         element: <App />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/caisse_noire",
//         element: <CaisseNoir />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/stockage",
//         element: <Stockage />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/historique",
//         element: <Historique />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/client",
//         element: <Client />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/remboursement",
//         element: <Remboursement />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/transaction",
//         element: <Transaction />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/annuaire",
//         element: <Annuaire />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/outils",
//         element: <Outils />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/gestion/item",
//         element: <GestionItem />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/gestion/user",
//         element: <GestionUser />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/gestion/prix",
//         element: <GestionPrice />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/statistique",
//         element: <Statistique />,
//         errorElement: <ErrorPage />
//       },
//       {
//         path: "/gestion/caisse_noire",
//         element: <GestionCN />,
//         errorElement: <ErrorPage />
//       },
//     ]
//   },
//   {
//     path: "/discord",
//     element: <Discord />,
//     errorElement: <ErrorPage />
//   },
// ]);